import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { setAlert, dispatchErrorMessage } from './globalAppSlice';

/**
 * Create a new folder
 * POST /api/create_folder
 */
export const createFolder = createAsyncThunk(
  'folders/createFolder',
  async ({ name, userId }, { dispatch }) => {
    try {
      const response = await axiosInstance.post('/api/create_folder', { name, userId });

      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data.msg || 'Folder created successfully',
            type: 'success',
            open: true,
          })
        );
        return response.data.data; // Return the created folder
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to create folder',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to create folder');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

/**
 * Get all folders for a user with pagination and search
 * GET /api/all_folder
 */
export const getAllFolders = createAsyncThunk(
  'folders/getAllFolders',
  async ({ userId, page = 1, limit = 200, search = '' }, { dispatch }) => {
    try {
      const response = await axiosInstance.get('/api/all_folder', {
        params: { userId, page, limit, search },
      });

      if (response?.data?.success) {
        return {
          folders: response.data.data,
          totalPages: response.data.totalPages,
        };
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to fetch folders',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to fetch folders');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

/**
 * Get details of a single folder, including maps
 * GET /api/folder/:folderId
 */
export const getFolderDetails = createAsyncThunk(
  'folders/getFolderDetails',
  async ({ folderId }, { dispatch }) => {
    try {
      const response = await axiosInstance.get(`/api/folder/${folderId}`);

      if (response?.data?.success) {
        return response.data.data;
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to fetch folder details',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to fetch folder details');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

/**
 * Add maps to a folder
 * POST /api/add_map_to_folder
 */
export const addMapToFolder = createAsyncThunk(
  'folders/addMapToFolder',
  async ({ folderId, mapIds, userId }, { dispatch }) => {
    try {
      const response = await axiosInstance.post('/api/add_map_to_folder', { folderId, mapIds, userId });

      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data.msg || 'Maps added to folder successfully',
            type: 'success',
            open: true,
          })
        );
        return response.data.data; // Return updated folder
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to add maps to folder',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to add maps to folder');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

/**
 * Remove a map from a folder
 * POST /api/remove_map_from_folder
 */
export const removeMapFromFolder = createAsyncThunk(
  'folders/removeMapFromFolder',
  async ({ folderId, mapId, userId }, { dispatch }) => {
    try {
      const response = await axiosInstance.post('/api/remove_map_from_folder', { folderId, mapId, userId });

      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data.msg || 'Map removed from folder successfully',
            type: 'success',
            open: true,
          })
        );
        return response.data.data; // Return updated folder
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to remove map from folder',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to remove map from folder');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

/**
 * Delete a folder
 * DELETE /api/delete_folder/:folderId
 */
export const deleteFolder = createAsyncThunk(
  'folders/deleteFolder',
  async ({ folderId, userId }, { dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/api/delete_folder/${folderId}`, {
        data: { userId },
      });

      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data.msg || 'Folder deleted successfully',
            type: 'success',
            open: true,
          })
        );
        return folderId; // Return the deleted folder ID
      } else {
        dispatch(
          setAlert({
            message: response.data.msg || 'Failed to delete folder',
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(response.data.msg || 'Failed to delete folder');
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error.response?.data?.msg || error.message);
    }
  }
);

const folderSlice = createSlice({
  name: 'folders',
  initialState: {
    folders: [],
    selectedFolder: null,
    totalPages: 1,
    status: 'idle',
    loading: false,
    error: null,
    history: [], // For undo operations
    future: [],  // For redo operations
  },
  reducers: {
    clearSelectedFolder(state) {
      state.selectedFolder = null;
    },
    undo(state) {
      const lastAction = state.history.pop();
      if (lastAction) {
        state.future.push(lastAction);
        // Implement logic to reverse the last action if needed
      }
    },
    redo(state) {
      const nextAction = state.future.pop();
      if (nextAction) {
        state.history.push(nextAction);
        // Implement logic to re-apply the action if needed
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Folder
   
      .addCase(createFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.folders.unshift(action.payload);
        state.history.push({ operation: 'create', folder: action.payload });
      })
  
      // Get All Folders
      .addCase(getAllFolders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllFolders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.folders = action.payload.folders;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getAllFolders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Get Folder Details
      .addCase(getFolderDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFolderDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedFolder = action.payload;
      })
      .addCase(getFolderDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Add Map to Folder
      .addCase(addMapToFolder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addMapToFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedFolder = action.payload;
        const index = state.folders.findIndex(folder => folder._id === updatedFolder._id);
        if (index !== -1) {
          state.folders[index] = updatedFolder;
          state.selectedFolder = updatedFolder;
          state.history.push({ operation: 'addMap', folder: updatedFolder });
        }
      })
      .addCase(addMapToFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Remove Map from Folder
   
      .addCase(removeMapFromFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedFolder = action.payload;
        const index = state.folders.findIndex(folder => folder._id === updatedFolder._id);
        if (index !== -1) {
          state.folders[index] = updatedFolder;
          state.selectedFolder = updatedFolder;
          state.history.push({ operation: 'removeMap', folder: updatedFolder });
        }
      })
      .addCase(removeMapFromFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Delete Folder
      .addCase(deleteFolder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const deletedFolderId = action.payload;
        state.folders = state.folders.filter(folder => folder._id !== deletedFolderId);
        if (state.selectedFolder && state.selectedFolder._id === deletedFolderId) {
          state.selectedFolder = null;
        }
        state.history.push({ operation: 'delete', folderId: deletedFolderId });
      })
      .addCase(deleteFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearSelectedFolder, undo, redo } = folderSlice.actions;

export default folderSlice.reducer;
